import {Combobox, Switch, Dialog, Transition} from "@headlessui/react";
import {useCallback, useEffect, useState, Fragment} from "react";
import {db} from "../config/firebase";
import employees from "../content/employees.json";
import {classNames} from "../util/helpers";
import {CheckIcon, ChevronDownIcon, BackspaceIcon} from "@heroicons/react/solid";
import { ExclamationIcon } from '@heroicons/react/outline'


const employeesArray = Object.entries(employees).map(([key, value]) => {
    return {
        email: key,
        ...value,
    };
});

export default function AddEvents() {
    const [events, setEvents] = useState([]);
    const [speakers, setSpeakers] = useState([]);
    const [query, setQuery] = useState("");
    const [all, setAll] = useState(false);
    const [edit, setEdit] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [remove, setRemove] = useState(null);

    const removeSpeaker = (email) => {
        const filteredSpeakers = speakers.filter(speaker => speaker.email !== email);
        setSpeakers(filteredSpeakers);
    }

    useEffect(async () => {
        const unsubscribe = await db.collection("events").onSnapshot((snap) => {
            const data = snap.docs.map((doc) => {
                const {start_at, end_at, ...restData} = doc.data();
                return {
                    ...restData,
                    start_at: start_at.toDate(),
                    end_at: end_at.toDate(),
                    id: doc.id,
                };
            });

            setEvents(data);
        });

        return unsubscribe;
    }, []);

    const filteredPeople =
        query === ""
            ? employeesArray.filter((_, index) => index < 9)
            : employeesArray
                .filter((employee) => {
                    return employee.name.toLowerCase().includes(query.toLowerCase());
                })
                .filter((_, index) => {
                    return index < 9;
                });

    function resetForm() {
        const form = document.getElementById("event-form");
        setSpeakers([]);
        setAll(false);
        setQuery("");
        setEdit(null);
        form.reset();
    }

    const saveEvent = useCallback(
        async (e) => {
            const formData = e.target;

            const start_at = new Date(
                `${e.target.date.value} ${e.target.start_at_time.value}`
            );
            const end_at = new Date(
                `${e.target.date.value} ${e.target.end_at_time.value}`
            );

            const data = {
                title: formData.title.value,
                summary: formData.summary.value,
                venue: formData.venue.value,
                track: formData.track.value,
                limit: formData.limit.value,
                start_at,
                end_at,
                speakers: [...speakers],
                ...(all ? {attending: ["all"]} : {attending: edit?.attending}),
            };


            if (edit) {
                await db.collection("events").doc(edit.id).set(data);
            } else {
                data.attending = [];
                await db.collection("events").add(data);
            }
            // clear everything now
            resetForm();
            e.preventDefault();
        },
        [speakers, all, edit]
    );

    const removeEvent = useCallback(async () => {
        if (remove) {
            await db.collection("events").doc(remove).delete();
        }
    }, [remove]);

    useEffect(() => {
        if (edit) {
            setSpeakers([]);
            const form = document.getElementById("event-form");
            const date = edit.start_at.getDate();

            function formatTime(num) {
                return String(num).padStart(2, "0");
            }

            const start_at_time = `${formatTime(
                edit.start_at.getHours()
            )}:${formatTime(edit.start_at.getMinutes())}`;

            const end_at_time = `${formatTime(edit.end_at.getHours())}:${formatTime(
                edit.end_at.getMinutes()
            )}`;
            form.title.value = edit.title;
            form.date.value = `2022-09-${date}`;
            form.start_at_time.value = start_at_time;
            form.end_at_time.value = end_at_time;
            form.summary.value = edit.summary;
            form.limit.value = edit.limit || 0;
            form.venue.value = edit.venue || "";
            form.track.value = edit.track || "";

            if (edit.attending?.includes("all")) {
                setAll(true);
            }
            if (edit.speakers) {
                setSpeakers([
                    ...edit.speakers
                ]);
            }
        }
    }, [edit]);

    useEffect(() => {
        if (!isOpen) {
            setRemove(null);
        }
    }, [isOpen]);

    return (
        <div>
            <header>
                <div className="max-w-7xl mx-auto py-5 pt-0">
                    <h1 className="text-3xl font-bold leading-tight text-terminal-blue">
                        Add Events
                    </h1>
                </div>
            </header>
            <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
                <form
                    id="event-form"
                    className="flex flex-col grid grid-cols-6 gap-6"
                    onSubmit={(e) => {
                        e.preventDefault();

                        saveEvent(e);
                    }}
                >
                    <div className="col-span-6">
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                            Title
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="title"
                                id="title"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="col-span-6">
                        <Combobox as="div" value={speakers} onChange={setSpeakers} multiple={true}>
                            <Combobox.Label className="block text-sm font-medium text-gray-700">Assigned
                                to</Combobox.Label>

                            <div
                                className="relative mt-1 items-center flex w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                            >
                                {speakers.length > 0 && (
                                    <ul className="flex h-full items-center">
                                        {speakers.map((speaker) => (
                                            <li className="text-sm flex items-center flex-shrink-0 px-2 py-1 mx-1 bg-gray-100 rounded" key={`selected-speaker-${speaker.email}`}>
                                                {speaker.name}
                                                <span className="text-red-500 cursor-pointer ml-1"><BackspaceIcon onClick={() => removeSpeaker(speaker.email)} className={"h-4 w-4"}/></span>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                                <Combobox.Input
                                    className="w-full h-0 border-0 flex-1 shadow-none"
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <Combobox.Button
                                    className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </Combobox.Button>

                                {filteredPeople.length > 0 && (
                                    <Combobox.Options
                                        style={{top: "100%", left: "0"}}
                                        className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {filteredPeople.map((speaker) => (
                                            <Combobox.Option
                                                key={speaker.email}
                                                value={speaker}
                                                className={({active}) =>
                                                    classNames(
                                                        'relative cursor-default select-none py-2 pl-3 pr-9',
                                                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                                                    )
                                                }
                                            >
                                                {({active, selected}) => (
                                                    <>
                                                    <span
                                                        className={classNames('block truncate', selected && 'font-semibold')}>{speaker.name}</span>

                                                        {selected && (
                                                            <span
                                                                className={classNames(
                                                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                                                    active ? 'text-white' : 'text-indigo-600'
                                                                )}
                                                            >
                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                      </span>
                                                        )}
                                                    </>
                                                )}
                                            </Combobox.Option>
                                        ))}
                                    </Combobox.Options>
                                )}
                            </div>
                        </Combobox>
                    </div>

                    <div className="col-span-6">
                        <Switch.Group>
                            <Switch.Label className="block text-sm font-medium text-gray-700">
                                Is event for everyone?
                            </Switch.Label>
                            <Switch
                                checked={all}
                                onChange={setAll}
                                className={`${
                                    all ? "bg-blue-600" : "bg-gray-200"
                                } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                            >
                                <span className="sr-only">Enable notifications</span>
                                <span
                                    className={`${
                                        all ? "translate-x-6" : "translate-x-1"
                                    } inline-block h-4 w-4 transform rounded-full bg-white`}
                                />
                            </Switch>
                        </Switch.Group>
                    </div>

                    <div className="col-span-6">
                        <label htmlFor="limit" className="block text-sm font-medium text-gray-700">
                            Limit attendance
                        </label>
                        <div className="mt-1">
                            <input
                                type="number"
                                name="limit"
                                id="limit"
                                defaultValue={0}
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2 col-span-6">
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                            Date
                        </label>
                        <div className="mt-1">
                            <input
                                type="date"
                                name="date"
                                id="date"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>


                    <div className="sm:col-span-2 col-span-6">
                        <label htmlFor="start_at_time" className="block text-sm font-medium text-gray-700">
                            Start time
                        </label>
                        <div className="mt-1">
                            <input
                                type="time"
                                name="start_at_time"
                                id="start_at_time"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-2 col-span-6">
                        <label htmlFor="end_at_time" className="block text-sm font-medium text-gray-700">
                            End time
                        </label>
                        <div className="mt-1">
                            <input
                                type="time"
                                name="end_at_time"
                                id="end_at_time"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-3 col-span-6">
                        <label htmlFor="venue" className="block text-sm font-medium text-gray-700">
                            Venue name
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="venue"
                                id="venue"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-3 col-span-6">
                        <label htmlFor="track" className="block text-sm font-medium text-gray-700">
                            Track
                        </label>
                        <div className="mt-1">
                            <input
                                type="text"
                                name="track"
                                id="track"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                required
                            />
                        </div>
                    </div>

                    <div className="col-span-6">
                        <label htmlFor="summary" className="block text-sm font-medium text-gray-700">
                            Summary
                        </label>
                        <div className="mt-1">
                        <textarea
                            rows={4}
                            name="summary"
                            id="summary"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            defaultValue={''}
                        />
                        </div>
                    </div>

                    <div className="">
                        <button
                            type="submit"
                            className="inline-flex items-center justify-center rounded-md border border-transparent bg-terminal-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-terminal-blue-hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                        >
                            Submit
                        </button>
                        {edit ? (
                            <button
                                className="mr-2 ml-auto text-termina-blue float-right"
                                onClick={resetForm}
                            >
                                Cancel
                            </button>
                        ) : (
                            ""
                        )}
                    </div>
                </form>
            </div>

            <div className="">
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col"
                                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                            Title
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Venue
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Date
                                        </th>
                                        <th scope="col"
                                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                            Speakers
                                        </th>
                                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {events.map((event) => (
                                        <tr key={`event-${event.id}`}>
                                            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                {event.title}
                                            </td>
                                            <td className="px-3 py-4 text-sm text-gray-500">{event.venue}</td>
                                          <td className="px-3 py-4 text-sm text-gray-500">
                                            {event?.speakers ?
                                                event.speakers.map((speaker, index) => {
                                                  return (
                                                      <>
                                                        {index > 0 && (<span className="mx-1">&</span>)}
                                                        <span className="truncate">{speaker.name}</span>
                                                      </>
                                                  )
                                                }) : (
                                                    <span className="truncate">13|37</span>
                                                )
                                            }
                                          </td>

                                          <td className="px-3 py-4 text-sm text-gray-500">
                                            <span className="text-terminal-blue">
                      {event.start_at.toLocaleString("sw-se", {
                        dateStyle: "short",
                        timeStyle: "short",
                      })}{" "}
                    </span>
                                            to
                                            <span className="text-terminal-blue">
                      {" "}
                                              {event.end_at.toLocaleString("si-se", {
                                                timeStyle: "short",
                                              })}
                    </span>
                                          </td>
                                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                            <button
                                                className="mr-2 text-red-500"
                                                onClick={() => {
                                                  // remove event
                                                  // add confirmation box
                                                  setIsOpen(true);
                                                  setRemove(event.id);
                                                }}
                                            >
                                              Remove
                                            </button>
                                            <button
                                                className="text-leet-green"
                                                onClick={() => {
                                                  // edit event
                                                  setEdit(event);
                                                }}
                                            >
                                              Edit
                                            </button>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


          <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10"  onClose={setIsOpen}>
              <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                      enterTo="opacity-100 translate-y-0 sm:scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                          <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                            Remove event
                          </Dialog.Title>
                          <div className="mt-2">
                            <p className="text-sm text-gray-500">
                              Are you sure you want to remove this event?
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => {
                              removeEvent();
                              setIsOpen(false);
                            }}
                        >
                          Deactivate
                        </button>
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                            onClick={() => setIsOpen(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>


        </div>
    );
}
