import { useEffect, useState } from "react";
import firebase, { db } from "../config/firebase";
import EventCard from "./EventCard";
import { classNames } from "../util/helpers";
import Loader from "./Loader";

export default function Ratings() {
  const user = firebase.auth().currentUser;
  const [ratings, setRatings] = useState(null);
  const [loading, setLoading] = useState(true);

  function SubmitRating(id, rating) {
    const ratingDoc = db.collection("ratings").doc(id);

    ratingDoc.update({ rating });
  }

  useEffect(async () => {
    const unsubscribe = await db
      .collection("ratings")
      .where("email", "==", user.email)
      .onSnapshot(async (ratings) => {
        if (!ratings) {
          return;
        }
        let myRatings = await Promise.all(
          ratings.docs.map(async (rating) => {
            const data = rating.data();

            const event = await db
              .collection("events")
              .doc(data.event_id)
              .get();
            return {
              ...data,
              id: rating.id,
              event: event.data(),
            };
          })
        );

        setRatings(myRatings);
        setLoading(false);
      });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <header>
        <div className="max-w-7xl mx-auto py-8">
          <h1 className="text-3xl font-bold leading-tight text-terminal-blue">
            Ratings
          </h1>
        </div>
      </header>
      {!loading ? (
        <div className="bg-gray-50">
          <div className="flex h-full flex-col">
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <ul role="list" className="divide-y divide-gray-200">
                {(ratings && ratings.length) ? (
                  ratings.map(({ id, event, rating }) => {
                    return (
                      <EventCard event={event} key={id}>
                        <div className="flex justify-between mt-3 ml-4 mr-4">
                          <span className="isolate inline-flex rounded-md shadow-sm">
                            <button
                              type="button"
                              onClick={() => SubmitRating(id, 1)}
                              className={classNames(
                                "relative text-lg inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                                rating === 1 && "bg-gray-200"
                              )}
                            >
                              😐
                            </button>
                            <button
                              type="button"
                              onClick={() => SubmitRating(id, 2)}
                              className={classNames(
                                "relative text-lg -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                                rating === 2 && "bg-gray-200"
                              )}
                            >
                              😊
                            </button>
                            <button
                              type="button"
                              onClick={() => SubmitRating(id, 3)}
                              className={classNames(
                                "relative text-lg -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500",
                                rating === 3 && "bg-gray-200"
                              )}
                            >
                              🤩
                            </button>
                          </span>
                        </div>
                      </EventCard>
                    );
                  })
                ) : (
                    <div className="text-center p-6">
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No ratings
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            They're going to appear here as soon as the events that you've signed up for are over.
                        </p>
                    </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <Loader />
          </div>
        </div>
      )}
    </>
  );
}
