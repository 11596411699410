import {Fragment, useEffect, useState, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {CameraIcon} from '@heroicons/react/outline'
import {XIcon} from "@heroicons/react/solid";
import firebase, {storage, db} from "../config/firebase";
import ProcessImage from 'react-imgpro';
import ExifReader from 'exifreader';
import Loader from "./Loader";

const imageMimeType = /image\/(png|jpg|jpeg)/i;

export default function ImageModal({open, setOpen}) {

    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const captionInputRef = useRef(null);
    const fileInputRef = useRef(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [rotation, setRotation] = useState(0);
    const processedImage = useRef();
    const storageRef = storage.ref();
    const user = firebase.auth().currentUser;

    const clearForm = () => {
        setOpen(false);
        setIsLoading(false);
        captionInputRef.current.value = null;
        setFile(null);
        setFileDataURL(null);
    }


    const uploadPost = async () => {
        setIsLoading(true);
        const imageId = `id-${new Date().getTime()}-${user.email}`;
        // Create a reference to 'mountains.jpg'
        const imageRef = storageRef.child(`images/posts/${imageId}`);
        const imagePath = imageRef.fullPath;

        await imageRef.putString(processedImage.current, "data_url").then((snapshot) => {
            console.log('Uploaded a blob or file!', snapshot);
        });

        await db.collection('posts').add({
            posted_by: user.displayName,
            caption: captionInputRef.current.value,
            created_at: new Date(),
            created_by: user.email,
            image: imagePath,
            likes: []
        });

        clearForm();
    }

    const changeHandler = (e) => {
        const file = e.target.files[0];
        if (!file.type.match(imageMimeType)) {
            alert("Image mime type is not valid");
            return;
        }
        setIsProcessing(true);
        setFile(file);
    }

    useEffect(() => {
        let fileReader, isCancel = false;
        if (file) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const {result} = e.target;
                if (result && !isCancel) {
                    const tags = ExifReader.load(result, {expanded: true});
                    tags.then((data) => {
                        if(!data?.exif?.Orientation?.description) {
                            setFileDataURL(result)
                        } else {
                            switch(data.exif.Orientation.description) {
                                case "right-top":
                                    setRotation(-90);
                                    break;
                                case "bottom-right":
                                    setRotation(180);
                                    break;
                                case "top-left":
                                    setRotation(0);
                                    break;
                            }
                            setFileDataURL(result)
                        }
                    })
                }
            }
            fileReader.readAsDataURL(file);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        }

    }, [file]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={clearForm}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        onClick={() => setOpen(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                <div>
                                    <div
                                        className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                        <CameraIcon className="h-6 w-6 text-green-600" aria-hidden="true"/>
                                    </div>
                                    <div className="mt-3 text-center sm:mt-5">
                                        <Dialog.Title as="h3"
                                                      className="text-lg leading-6 font-medium text-gray-900 py-3">
                                            Create post
                                        </Dialog.Title>

                                        <div
                                            className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                            <div className="space-y-1 text-center">
                                                {fileDataURL ? (
                                                        <p className="img-preview-wrapper min-h-4">
                                                            <span className={!isProcessing && "hidden"}>
                                                                <Loader/>
                                                            </span>
                                                            <ProcessImage
                                                                className={isProcessing && "hidden"}
                                                                image={fileDataURL}
                                                                resize={{ width: 940 }}
                                                                rotate={{ degree: rotation}}
                                                                processedImage={(src, err) => {
                                                                    processedImage.current = src;
                                                                }}
                                                                onProcessFinish={() => {
                                                                    setIsProcessing(false);
                                                                }}
                                                            />
                                                        </p>
                                                    )
                                                    : (
                                                        <>
                                                            <svg
                                                                className="mx-auto h-12 w-12 text-gray-400"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                viewBox="0 0 48 48"
                                                                aria-hidden="true"
                                                            >
                                                                <path
                                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                                    strokeWidth={2}
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                            <div className="flex text-sm text-gray-600">
                                                                <label
                                                                    htmlFor="file-upload"
                                                                    className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                                                >
                                                                    <span>Upload a file</span>
                                                                    <input
                                                                        type="file"
                                                                        id="file-upload"
                                                                        name="file-upload"
                                                                        ref={fileInputRef}
                                                                        className="sr-only"
                                                                        accept='.png, .jpg, .jpeg'
                                                                        onChange={changeHandler}
                                                                    />
                                                                </label>
                                                                <p className="pl-1">or drag and drop</p>
                                                            </div>
                                                            <p className="text-xs text-gray-500">PNG, JPG, GIF up to
                                                                10MB</p>
                                                        </>
                                                    )}

                                            </div>
                                        </div>

                                        <div>
                                            <div
                                                className="mt-2 border-b border-gray-300 focus-within:border-indigo-600">
                                                <input
                                                    ref={captionInputRef}
                                                    type="text"
                                                    className="block w-full border-0 border-b border-transparent p-2 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                                                    placeholder="Write a caption... "
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-6">
                                    <button
                                        disabled={isLoading}
                                        type="button"
                                        className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm disabled:opacity-75"
                                        onClick={() => uploadPost()}
                                    >
                                        {isLoading ? "Uploading ..." : "Upload"}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}