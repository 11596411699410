import {handleCancel, handleSignUp} from "./Events";
import {classNames, isSameTime} from "../util/helpers";
import {useCurrentEvents} from "../hooks/useMyCurrentEvents";

export default function SignUpButton({event, user}) {
    const {events} = useCurrentEvents();


    if (event.attending && event.attending.includes("all"))
    {
        return null
    }
    else if(event.attending?.find((email) => email.split("@")[0] === user.email.split("@")[0])) {
        return (
            <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-terminal-blue"
                onClick={(e) => {
                    e.preventDefault();
                    handleCancel(event.id, user);
                }}
            >
                Cancel
            </button>
        )
    }
    else if(
        events &&
        (events.filter(({attending}) => !attending.includes("all")).find(({start_at}) => isSameTime(start_at, event.start_at))) ||
        (event.limit > 0 && (event?.attending?.length >= event.limit))
    ) {
        return (
            <>
            <button
                disabled
                type="button"
                className={classNames( "inline-flex disabled:opacity-50 items-center px-4 py-2 opa border border-transparent text-sm opacity- font-medium rounded-md shadow-sm text-white bg-terminal-blue focus:outline-none")}
            >
                Sign Up
            </button>
            </>
        )
    }
    else {
        return (
            <button
                type="button"
                className={classNames( "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-terminal-blue hover:bg-terminal-blue-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-terminal-blue")}
                onClick={(e) => {
                    e.preventDefault();
                    handleSignUp(event.id, user);
                }}
            >
                Sign Up
            </button>
        )
    }
}