import firebase, { storage } from "../config/firebase";
import GoogleButton from 'react-google-button'

const Login = () => {
  return (
    <div className="h-screen flex flex-col justify-around items-center bg-terminal-blue">
        <div className="pl-20 pr-20 w-full flex justify-center items-center">
          <img
            style={{ objectFit: "cover" }}
            className="h-auto"
            style={{width: "20rem"}}
            src="/assets/Knowabunga-logo.svg"
          />
        </div>

      <GoogleButton
        type="light"
        onClick={() => {
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          googleAuthProvider.setCustomParameters({
            hd: "1337.tech",
          });
          firebase
            .auth()
            .signInWithRedirect(googleAuthProvider)
            .then(
              async function (result) {
                // The firebase.User instance:
                var user = result.user;

                if (!user.email.endsWith("@1337.tech")) {
                  await firebase.auth().currentUser.delete();
                }
              },
              function (error) {
                // The provider's account email, can be used in case of
                // auth/account-exists-with-different-credential to fetch the providers
                // linked to the email:
                console.error({ error });
              }
            );
        }}
      />
    </div>
  );
};

export default Login;
