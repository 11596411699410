import {FirebaseAuthConsumer} from "@react-firebase/auth";
import PostSlider from "./PostSlider";
import {PlusSmIcon} from '@heroicons/react/outline'
import ImageModal from "./ImageModal";
import {useEffect, useState} from "react";
import {db, storage} from "../config/firebase";
import Loader from "./Loader";

const Feed = () => {
    const [open, setOpen] = useState(false)
    const [posts, setPosts] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const storageRef = storage.ref();
    useEffect(() => {
        const fetchData = async (snap) => {
            const data = await Promise.all(snap.docs.map(async doc => {
                const {created_at, image, ...restData} = doc.data();
                const imageUrl = await storageRef.child(image).getDownloadURL();

                return {...restData, created_at: created_at.toDate(), image: imageUrl, id: doc.id}
            }))

            setPosts(data)
            setIsLoading(false);
        }

        const unsubscribe = db.collection("posts").orderBy("created_at", "desc").onSnapshot(snap => {
            fetchData(snap).catch(console.error);
        });

        return () => unsubscribe();

    }, []);

    console.log({isLoading})

    return (
        <>
            <div className="container mx-auto" style={{maxWidth: "470px"}}>
                <header>
                    <div className="max-w-7xl mx-auto py-8">
                        <h1 className="text-3xl font-bold leading-tight text-terminal-blue">Feed</h1>
                    </div>
                </header>


                {!isLoading ? (
                    posts ? (
                        <PostSlider posts={posts}/>
                    ) : (
                        <div className="text-center p-6 bg-white bg-white shadow overflow-hidden sm:rounded-md">
                            <h3 className="mt-2 text-sm font-medium text-gray-900">
                                No posts
                            </h3>
                            <p className="mt-1 text-sm text-gray-500">
                                They're going to appear here as soon as the first post is created.
                            </p>
                        </div>
                    )
                ) : (
                    <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                        <div className="max-w-max mx-auto">
                            <Loader/>
                        </div>
                    </div>
                )}

                <button
                    onClick={() => setOpen(true)}
                    type="button"
                    style={{boxShadow: `0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.25)`}}
                    className="inline-flex fixed shadow-2xl bottom-8 right-8 items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-terminal-blue hover:bg-terminal-blue-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                    <PlusSmIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
            </div>

            <ImageModal open={open} setOpen={setOpen}/>
        </>
    )
}

export default Feed;