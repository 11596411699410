import {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import firebase, {db} from "../config/firebase";
import Loader from "./Loader";
import {findPicture, prettyDate} from "../util/helpers";
import {ChatIcon} from "@heroicons/react/outline";
import {ExclamationCircleIcon} from "@heroicons/react/solid";
import SignUpButton from "./SignUpButton";

export default function Event() {
    let {id} = useParams();
    const [data, setData] = useState(null);
    const [comments, setComments] = useState(null);
    const commentInputRef = useRef(null);

    const user = firebase.auth().currentUser;

    const addComment = async () => {
        await db.collection(`events/${id}/comments`).add({
            content: commentInputRef.current.value,
            created_at: new Date(),
            author: {
                name: user.displayName,
                email: user.email,
            },
        });
    };

    const deleteComment = async (commentId) => {
        await db.collection(`events/${id}/comments`).doc(commentId).delete();
    };

    useEffect(() => {
        const unsubEvents = db
            .collection("events")
            .doc(id)
            .onSnapshot((doc) => {
                const {start_at, end_at, ...restData} = doc.data();
                const data = {
                    ...restData,
                    end_at: end_at.toDate(),
                    start_at: start_at.toDate(),
                    id: doc.id,
                };
                setData(data);
            });

        const unsubComments = db
            .collection(`events/${id}/comments`)
            .orderBy("created_at", "desc")
            .onSnapshot((snap) => {
                const data = snap.docs.map((doc) => {
                    const {created_at, ...restData} = doc.data();
                    return {...restData, created_at: created_at.toDate(), id: doc.id};
                });
                setComments(data);
            });

        return () => {
            unsubEvents();
            unsubComments();
        };
    }, []);

    console.log({data});

    if (data) {
        return (
            <>
                <div className="min-h-full">
                    <main className="py-10">
                        {/* Page header */}
                        <div
                            className="mx-auto max-w-3xl px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
                            <div className="flex items-center space-x-5">
                                {data?.speakers  && data.speakers.length > 0 ? (
                                    <div className="flex-shrink-0">
                                        <div className="isolate flex -space-x-4 items-center">
                                            {data.speakers.map(speaker => (
                                                <div className="relative">
                                                    <img
                                                        className="relative z-0 inline-block h-16 w-16 rounded-full ring-2 ring-white object-cover object-top"
                                                        src={findPicture(speaker.email)}
                                                        alt=""
                                                    />
                                                    <span
                                                        className="absolute inset-0 rounded-full shadow-inner"
                                                        aria-hidden="true"
                                                    />
                                                </div>

                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-16 w-16 rounded-full object-contain bg-terminal-blue"
                                            src="/logo-square.svg"
                                            alt=""
                                        />
                                    </div>
                                )}
                                <div>
                                    <h1 className="text-lg sm:text-2xl font-bold text-gray-900">
                                        {data?.title}
                                    </h1>
                                    <p className="text-sm font-medium text-gray-500">
                                        Presented by{" "}
                                        <span className="text-gray-900">
                      {data?.speakers ?
                          data.speakers.map((speaker, index) => {
                              return (
                                  <>
                                      {index > 0 && (<span className="mx-1">&</span>)}
                                      <span className="truncate">{speaker.name}</span>
                                  </>
                              )
                          }) : (
                              <span className="truncate">13|37</span>
                          )
                      }
                    </span>{" "}
                                        on{" "}
                                        <time dateTime={data.start_at.toISOString()}>
                                            {new Intl.DateTimeFormat("en-GB", {
                                                dateStyle: "full",
                                            }).format(data.start_at)}
                                        </time>
                                        {" "}at{" "}
                                        <time dateTime={data.start_at.toISOString()}>
                                            {new Intl.DateTimeFormat("en-GB", {
                                                hour: "numeric",
                                                minute: "numeric",
                                            }).format(data.start_at)}
                                        </time>
                                    </p>
                                </div>
                            </div>

                            <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                                <SignUpButton event={data} user={user}/>
                            </div>
                        </div>


                        <div
                            className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense">
                            <div className="space-y-6 lg:col-span-2 lg:col-start-1">

                                {data.limit > 0 && (
                                    <div className="rounded-md bg-yellow-50 p-4">
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationCircleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-yellow-800">Limited attendance</h3>
                                                <div className="mt-2 text-sm text-yellow-700">
                                                    <p>
                                                        This is a limited attendance event. <span className="font-medium">{data?.attending?.length ? data.limit - data.attending.length : data.limit}/{data.limit}</span> of seats are currently available.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {/* Description list*/}
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="px-4 py-5 sm:px-6">
                                            <h2
                                                id="applicant-information-title"
                                                className="text-lg font-medium leading-6 text-gray-900"
                                            >
                                                Details
                                            </h2>
                                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                                Find out more about this Knowabunga event.
                                            </p>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                                {data.speaker?.name ? (
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">
                                                            Speaker
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {data.speaker.name}
                                                        </dd>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {data.track ? (
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">
                                                            Track
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            {data.track}
                                                        </dd>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">
                                                        Venue
                                                    </dt>
                                                    <dd className="mt-1 text-sm text-gray-900">
                                                        {data.venue}
                                                    </dd>
                                                </div>
                                                {!data.attending?.includes("all") ? (
                                                    <div className="sm:col-span-1">
                                                        <dt className="text-sm font-medium text-gray-500">
                                                            Attendees
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900">
                                                            <div
                                                                className="isolate flex -space-x-2 overflow-hidden items-center">
                                                                {(!data.attending || data.attending.length === 0) && (
                                                                    <span
                                                                        className="flex align-center items-center"><ChatIcon
                                                                        className="block h-6 w-6 text-gray-500 mr-1"
                                                                        aria-hidden="true"/>{" "}Be the first one to attend!</span>
                                                                )}
                                                                {data.attending?.slice(0, 5).map((attendee) => {
                                                                    return (
                                                                        <img
                                                                            className="relative z-0 inline-block h-8 w-8 rounded-full ring-2 ring-white object-cover object-top"
                                                                            src={findPicture(attendee)}
                                                                            alt=""
                                                                        />
                                                                    );
                                                                })}
                                                                {data.attending?.length > 6 && (
                                                                    <div className="!ml-2 text-sm text-gray-900">
                                                                        + {data.attending.length - 6}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </dd>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                                {data.summary && (
                                                    <div className="sm:col-span-2">
                                                        <dt className="text-sm font-medium text-gray-500">
                                                            Summary
                                                        </dt>
                                                        <dd className="mt-1 text-sm text-gray-900 whitespace-pre-wrap">
                                                            {data.summary}
                                                        </dd>
                                                    </div>
                                                )}
                                            </dl>
                                        </div>
                                    </div>
                                </section>

                                {/* Comments*/}
                                {
                                    <section aria-labelledby="notes-title">
                                        <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                                            <div className="divide-y divide-gray-200">
                                                <div className="px-4 py-5 sm:px-6">
                                                    <h2
                                                        id="notes-title"
                                                        className="text-lg font-medium text-gray-900"
                                                    >
                                                        Comments
                                                    </h2>
                                                </div>
                                                <div className="px-4 py-6 sm:px-6">
                                                    <ul role="list" className="space-y-8">
                                                        {comments && comments.length > 0 ? (
                                                            comments.map((comment) => (
                                                                <li key={comment.id}>
                                                                    <div className="flex space-x-3">
                                                                        <div className="flex-shrink-0">
                                                                            <img
                                                                                className="h-10 w-10 rounded-full object-cover object-top"
                                                                                src={findPicture(comment.author.email)}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                        <div>
                                                                            <div className="text-sm">
                                        <span className="font-medium text-gray-900">
                                          {comment.author.name}
                                        </span>
                                                                            </div>
                                                                            <div className="mt-1 text-sm text-gray-700">
                                                                                <p>{comment.content}</p>
                                                                            </div>
                                                                            <div className="mt-2 space-x-2 text-sm">
                                        <span className="font-medium text-gray-500">
                                          {prettyDate(comment.created_at)}
                                        </span>{" "}
                                                                                {comment.author.email ===
                                                                                    user.email && (
                                                                                        <span
                                                                                            onClick={() =>
                                                                                                deleteComment(comment.id)
                                                                                            }
                                                                                            className="font-medium text-red-500 cursor-pointer"
                                                                                        >
                                            Delete
                                          </span>
                                                                                    )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            ))
                                                        ) : (
                                                            <div className="text-center">
                                                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                                                    No comments
                                                                </h3>
                                                                <p className="mt-1 text-sm text-gray-500">
                                                                    Start the discussion by creating a new
                                                                    comment.
                                                                </p>
                                                            </div>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="bg-gray-50 px-4 py-6 sm:px-6">
                                                <div className="flex space-x-3">
                                                    <div className="flex-shrink-0">
                                                        <img
                                                            className="h-10 w-10 rounded-full object-cover object-top"
                                                            src={findPicture(user.email)}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div className="min-w-0 flex-1">
                                                        <div>
                                                            <label htmlFor="comment" className="sr-only">
                                                                About
                                                            </label>
                                                            <textarea
                                                                ref={commentInputRef}
                                                                name="comment"
                                                                rows={3}
                                                                className="block w-full p-3 rounded-md border border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                                                placeholder="Add a note"
                                                                defaultValue={""}
                                                            />
                                                        </div>
                                                        <div className="mt-3 flex items-center justify-between">
                                                            <button
                                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-terminal-blue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-terminal-blue-hover focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                                                onClick={addComment}
                                                            >
                                                                Comment
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                }
                            </div>
                        </div>
                    </main>
                </div>
            </>
        );
    } else {
        return (
            <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                    <Loader/>
                </div>
            </div>
        );
    }
}
