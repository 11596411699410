// for my current and next event that is coming up
import {useCurrentEvents} from "../hooks/useMyCurrentEvents";
import {InformationCircleIcon, ClockIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

export default function MyCurrentEvents() {
    const {currentEvent, nextEvent} = useCurrentEvents();

    return (
        <div className="flex flex-col sm:flex-row w-full">
            {currentEvent && (
                <div className="rounded-md bg-white p-4 w-full shadow-sm mb-4">
                    <Link to={`/signup/${currentEvent.id}`}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ClockIcon className="h-5 w-5 text-leet-green" aria-hidden="true"/>
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm">{currentEvent.title} is ongoing in {currentEvent.venue}. Hurry!</p>
                                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                                    <span href="#"
                                       className="whitespace-nowrap font-medium text-terminal-blue hover:text-terminal-blue-hover">
                                        Details
                                        <span aria-hidden="true"> &rarr;</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            )}
            {nextEvent && (
                <div className="rounded-md bg-white p-4 w-full shadow-sm mb-4">
                    <Link to={`/signup/${nextEvent.id}`}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <InformationCircleIcon className="h-5 w-5 text-terminal-blue" aria-hidden="true"/>
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className="text-sm"><span
                                    className="font-medium">{nextEvent.title}</span> is starting at <span
                                    className="font-medium">{nextEvent.start_at}</span> in {nextEvent.venue}.</p>
                                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                                    <span href="#"
                                       className="whitespace-nowrap font-medium text-terminal-blue hover:text-terminal-blue-hover">
                                        Details
                                        <span aria-hidden="true"> &rarr;</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            )}
        </div>
    );
}
