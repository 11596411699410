import {useContext, createContext, useState, useEffect} from "react";
import firebase, {db} from "../config/firebase";

const CurrentEventsContext = createContext(null);

export function useCurrentEvents() {
    return useContext(CurrentEventsContext);
}

export default function CurrentEventsProvider({children}) {
    const [events, setEvents] = useState(null);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [nextEvent, setNextEvent] = useState(null);
    const user = firebase.auth().currentUser;
    const [currentTime, setCurrentTime] = useState(new Date().getTime());

    const {email} = user;

    useEffect(async () => {
        const unsubscribe = db
            .collection("events")
            .where("attending", "array-contains-any", [email, "all"])
            .onSnapshot((events) => {
                let myEvents = [];
                events.docs.forEach((doc) => {
                    const data = doc.data();
                    myEvents.push({
                        ...data,
                        id: doc.id,
                        start_at: new Date(data.start_at.toDate()).getTime(),
                        end_at: new Date(data.end_at.toDate()).getTime(),
                    });
                });

                setEvents(myEvents);
            });

        return unsubscribe;
    }, [email]);

    useEffect(() => {
        // for setting time interval
        const interval = setInterval(() => {
            setCurrentTime(new Date().getTime());
        }, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (events) {
            const [currentEvent] = events.filter((event) => {
                return event.start_at <= currentTime && currentTime < event.end_at;
            });

            const [nextEvent] = events
                .filter((event) => {
                    return event.start_at >= (currentEvent?.end_at || currentTime);
                })
                .sort((a, b) => {
                    if (a.start_at > b.start_at) {
                        return 1;
                    }
                    return -1;
                });

            setCurrentEvent(
                Object.keys(currentEvent || {}).length > 0
                    ? {
                        ...currentEvent,
                        start_at: formatTimeFromDate(currentEvent?.start_at),
                    }
                    : null
            );
            setNextEvent(
                Object.keys(nextEvent || {}).length > 0
                    ? {
                        ...nextEvent,
                        start_at: formatTimeFromDate(nextEvent?.start_at),
                    }
                    : null
            );
        }
    }, [events, currentTime]);

    function formatTimeFromDate(date) {
        if (!date) {
            return null;
        }
        const hours = new Date(date).getHours();
        const minutes = new Date(date).getMinutes();

        return `${hours}:${String(minutes).padStart(2, "0")}`;
    }

    return (
        <CurrentEventsContext.Provider
            value={{
                events,
                currentEvent,
                nextEvent,
            }}
        >
            {children}
        </CurrentEventsContext.Provider>
    );
}
