import firebase from "../config/firebase";

const BASE_QR_CODE_API = "https://chart.googleapis.com/chart";

export default function QRCode() {
  const user = firebase.auth().currentUser;
  const { email } = user;

  const [name] = email.split("@");

  const UserQRCode = `${BASE_QR_CODE_API}?chs=500x500&cht=qr&chl=${name}`;

  return <img className="w-11/12" src={UserQRCode}></img>;
}
