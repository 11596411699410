/* This example requires Tailwind CSS v2.0+ */
import firebase, { db } from "../config/firebase";
import { useEffect, useState } from "react";
import {Link, useLocation, useSearchParams} from "react-router-dom";
import { PlusCircleIcon, PlusSmIcon } from "@heroicons/react/outline";
import Tabs from "./Tabs";
import Loader from "./Loader";
import { EventCardWithLink } from "./EventCard";
import SignUpButton from "./SignUpButton";

export default function Calendar() {
  const [events, setEvents] = useState(null);
  const [tabs, setTabs] = useState(null);
  const [currentTab, setCurrentTab] = useState(null);
  let [searchParams, _] = useSearchParams();

  const user = firebase.auth().currentUser;

  useEffect(() => {
    const activeTab = searchParams.get("tabName");
    if(activeTab) {
      setCurrentTab(activeTab);
    }
  })

  useEffect(() => {
    const unsubscribe = db.collection("events")
        .where("attending", "array-contains-any", [user.email, "all"])
        .onSnapshot((snap) => {
      const data = snap.docs
          .map((doc) => {
            const { start_at, ...restData } = doc.data();
            return { ...restData, start_at: start_at.toDate(), id: doc.id };
          });

      data.sort((a, b) => {
        let dateA = a.start_at;
        let dateB = b.start_at;

        return dateA.getTime() - dateB.getTime();
      });

      const dateMap = new Map();
      const tabsSet = new Set();

      data.forEach((event) => {
        const day = event.start_at.getDate();
        const month = event.start_at.getMonth() + 1;
        const hour = event.start_at.getHours();
        const minute = event.start_at.getMinutes();

        const dateIndex = `${String(day).padStart(2, "0")}.${String(
            month
        ).padStart(2, "0")}`;
        const timeIndex = `${String(hour).padStart(2, "0")}:${String(
            minute
        ).padStart(2, "0")}`;

        tabsSet.add(dateIndex);

        if (dateMap.has(dateIndex)) {
          const current = dateMap.get(dateIndex);

          if (current.hasOwnProperty(timeIndex)) {
            current[timeIndex].push(event);
          } else {
            current[timeIndex] = [event];
          }
        } else {
          const current = {
            [timeIndex]: [event],
          };

          dateMap.set(dateIndex, current);
        }
      });

      const tabs = Array.from(tabsSet);
      setEvents(dateMap);
      setTabs(tabs);
      setCurrentTab(tabs[0]);

    });

    return () => unsubscribe();
  }, []);

  if ((events && events.size > 0) && (tabs && tabs.length > 0) && currentTab) {
    return (
        <>
          <header>
            <div className="max-w-7xl mx-auto py-8">
              <h1
                  className="text-3xl font-bold leading-tight text-terminal-blue"
              >
                Schedule
              </h1>
            </div>
          </header>

          <div className="py-6 pt-0">
            <Tabs active={currentTab} tabs={tabs} setTab={setCurrentTab} />
          </div>

          <div className="bg-white shadow overflow-hidden sm:rounded-md">
            <ul role="list" className="divide-y divide-gray-200">
              {Object.entries(events.get(currentTab) || {})?.map(
                  ([key, events], index) => {
                    return (
                        <>
                          <div
                              className={`bg-gray-50 px-4 py-2 text-sm font-medium text-gray-900 sm:px-6 ${
                                  index !== 0 && `border-b`
                              } text-right`}
                          >
                            {key}
                          </div>
                          {events.map((event) => (
                              <EventCardWithLink event={event}/>
                          ))}
                        </>
                    );
                  }
              )}
            </ul>
          </div>
        </>
    );
  } else {
    return (
        <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <Loader />
          </div>
        </div>
    );
  }
}
