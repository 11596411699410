import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyBRZlHmTWY2_4UsfC_jg9AKy5u47fqMBXI",
    authDomain: "tube-1ae88.firebaseapp.com",
    projectId: "tube-1ae88",
    storageBucket: "tube-1ae88.appspot.com",
    messagingSenderId: "182178411740",
    appId: "1:182178411740:web:8162819f539d04d947b019",
    measurementId: "G-YVCENPPYFY"
};

firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
const auth = firebase.auth;
const functions = firebase.functions();
const storage = firebase.storage();


// eslint-disable-next-line no-restricted-globals
if (process.env["REACT_APP_EMULATED"]) {
    db.useEmulator('localhost', 8080);
    auth().useEmulator('http://localhost:9099/');
    functions.useEmulator("localhost", 5001);
}

export default firebase;
export {firebaseConfig, db, auth, functions, storage}