import {ArrowRightIcon, ArrowLeftIcon} from '@heroicons/react/solid'
import {StarIcon} from '@heroicons/react/outline'
import {useProfileData} from "../hooks/useProfileData";
import firebase from "../config/firebase";
import * as employees from '../content/employees.json';
import Loader from "./Loader";

export default function Details() {
    const {flight, group, speaker, isLoading} = useProfileData();
    const user = firebase.auth().currentUser;

    return (
        <>
            <header>
                <div className="max-w-7xl mx-auto py-8">
                    <h1
                        className="text-3xl font-bold leading-tight text-terminal-blue"
                    >
                        Details
                    </h1>
                </div>
            </header>

            {isLoading ? (
                <div className="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                    <div className="max-w-max mx-auto">
                        <Loader/>
                    </div>
                </div>
            ) : (
                <>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg mb-4">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Personal information</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal and group details.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                {user?.displayName && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Full name</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{user.displayName}</dd>
                                    </div>
                                )}
                                {speaker?.t && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Role type</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 capitalize">{speaker.t ? speaker.t.toLowerCase() : "Guest"}</dd>
                                    </div>
                                )}
                                {group?.n && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Group no.</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">3</dd>
                                    </div>
                                )}
                                {(group?.l && group?.m.length > 0) && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Group</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <ul>
                                                <li><StarIcon className="inline h-6 w-6 pr-2 text-gray-500"
                                                              aria-hidden="true"/>{employees[group.l].name}</li>
                                                {group.m.map(member => {
                                                    return (
                                                        <li key={`group-member-name-${member}`}>{employees[member]?.name ?? member}</li>
                                                    )

                                                })}
                                            </ul>
                                        </dd>
                                    </div>
                                )}
                            </dl>
                        </div>
                    </div>
                    <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg font-medium leading-6 text-gray-900">Travel information</h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">Accommodation and flight details.</p>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                {(flight.to && flight.back) && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Flights</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                            <p><ArrowRightIcon className="inline h-6 w-6 pr-2 text-gray-500"
                                                               aria-hidden="true"/>{flight.to}</p>
                                            <p><ArrowLeftIcon className="inline h-6 w-6 pr-2 text-gray-500"
                                                              aria-hidden="true"/>{flight.back}</p>
                                        </dd>
                                    </div>
                                )}
                                {speaker?.r?.t && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Room type</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 capitalize">{speaker.r.t.toLowerCase()}</dd>
                                    </div>
                                )}
                                {speaker?.r?.n && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Room number</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{speaker?.r?.n}</dd>
                                    </div>
                                )}
                                {speaker?.r?.nf && (
                                    <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                                        <dt className="text-sm font-medium text-gray-500">Room buddy</dt>
                                        <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{speaker.r.nf}</dd>
                                    </div>
                                )}
                            </dl>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}