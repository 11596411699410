import {createContext, useContext, useEffect, useState} from "react";
import firebase from "../config/firebase";

const peopleUrl = "https://docs.google.com/spreadsheets/d/1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o/export?format=csv&id=1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o&gid=0&range=A1";
const flightsUrl = "https://docs.google.com/spreadsheets/d/1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o/export?format=csv&id=1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o&gid=0&range=A2";
const groupsUrl = "https://docs.google.com/spreadsheets/d/1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o/export?format=csv&id=1_P-qMKJZqn7MmXoBDoXJWHulJWQIDjGem25m-QUN93o&gid=0&range=A3";

async function parseUrl(url) {
    const data = await fetch(url);
    const text = await data.text();

    const parsed = JSON.parse(text.replaceAll('""','"').substring(1).slice(0, -1));
    return parsed;
}

const ProfileDataContext = createContext(null);

export function useProfileData() {
    return useContext(ProfileDataContext);
}

export default function ProfileDataProvider({children}) {
    const [flight, setFlight] = useState(null);
    const [group, setGroup] = useState(null);
    const [speaker, setSpeaker] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const user = firebase.auth().currentUser;
    const userShorthand = user.email.split("@")[0];

    useEffect(async () => {
        const flightsPromise = parseUrl(flightsUrl);
        const groupsPromise =  parseUrl(groupsUrl);
        const peoplePromise =  parseUrl(peopleUrl);

        const fetchData = async () => {
            const [flights, groups, people] = await Promise.all([flightsPromise, groupsPromise, peoplePromise]);

            const speakerData = people[userShorthand];
            let groupData = null;

            if(speakerData.g !== "N/A") {
                groupData = groups[`Group${speakerData.g}`];
            }

            const flightData = {
                to: speakerData?.f?.t ? flights[speakerData.f.t].replace('<br>', '') : null,
                back: speakerData?.f?.b ? flights[speakerData.f.t].replace('<br>', '') : null
            }

            setSpeaker(speakerData);
            setGroup(groupData);
            setFlight(flightData);
            setIsLoading(false);
        }

        fetchData().catch(console.error);

    }, [user.shorthand]);

    return (
        <ProfileDataContext.Provider
            value={{flight, group, speaker, isLoading}}
        >
            {children}
        </ProfileDataContext.Provider>
    )
}