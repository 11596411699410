import {useLocation, useNavigate} from "react-router-dom";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Tabs({tabs, active, setTab}) {
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
                <a
                    key={tab.name}
                    href={tab.href}
                    onClick={() => {;
                        navigate(`${location.pathname}?tabName=${tab}`);
                        setTab(tab)
                    }}
                    className={classNames(
                        active === tab ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                        tabIdx === 0 ? 'rounded-l-lg' : '',
                        tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                        'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-bold text-center hover:bg-gray-50 focus:z-10 cursor-pointer'
                    )}
                    aria-current={active === tab ? 'page' : undefined}
                >
                    <span>{tab}</span>
                    <span
                        aria-hidden="true"
                        className={classNames(
                            active === tab ? 'bg-leet-green' : 'bg-transparent',
                            'absolute inset-x-0 bottom-0 h-0.5'
                        )}
                    />
                </a>
            ))}
        </nav>
    )
}
