import Post from "./Post";
import {useState} from "react";

const PostSlider = ({posts}) => {
    const [index, setIndex] = useState(1);

    return (
            <div className="container">
                {posts.slice(0, index * 5).map((post, index) => <Post key={`post-${index}-${post.created_at.toDateString()}`} post={post}/>)}

                {(posts.length > 5 && index * 5 < posts.length) && (
                    <div className="relative mt-8 mb-8">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center">
                            <button
                                onClick={() => setIndex(index + 1)}
                                type="button"
                                className="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                                <span>Load More</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
    )
}

export default PostSlider;