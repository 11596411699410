import {HeartIcon as HeartIconOutline} from "@heroicons/react/outline";
import {HeartIcon as HeartIconSolid} from "@heroicons/react/solid";
import {useState} from "react";
import {findPicture, prettyDate} from "../util/helpers";
import firebase, {db} from "../config/firebase";

const Post = ({post}) => {

    const user = firebase.auth().currentUser;
    const {posted_by, created_by, caption, created_at, image, id, likes} = post;
    const [isLiked, setIsLiked] = useState(likes?.find(el => el === user.email));
    const storyRef = db.collection('posts').doc(id);

    const postLike = () => {
        setIsLiked(true)
        storyRef.update({ likes: firebase.firestore.FieldValue.arrayUnion(user.email) });
    }

    const postUnlike = () => {
        setIsLiked(false)
        storyRef.update({ likes: firebase.firestore.FieldValue.arrayRemove(user.email) });
    }

    return (
        <div className="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200 my-4">
            <div className="px-3 py-3">
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <img
                            src={findPicture(created_by)}
                            alt="Profile picture" className="h-8 w-8 rounded-full object-cover object-top"/>
                            <div className="ml-4">
                                <h4 className="text-sm font-bold text-gray-900">{posted_by}</h4>
                            </div>
                    </div>
                    <div className="flex items-center">
                        <div className="h-6 w-6 flex text-red-500 items-center cursor-pointer">
                            {isLiked ? (
                                <HeartIconSolid onClick={postUnlike}/>
                            ) : (
                                <HeartIconOutline onClick={postLike}/>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <img loading="lazy" alt="Post image" src={image} className="object-cover h-auto w-full"/>
            </div>
            <div className="px-4 py-4 sm:px-6">
                <p className="text-sm font-bold text-red-500 py-1">{likes.length} like{(likes.length > 1  || likes.length === 0) && ("s")}</p>
                <p className="text-stone-600 text-sm">{caption}</p>
                <p className="text-stone-400 py-2 text-xs">{prettyDate(created_at)}</p>
            </div>
        </div>
    )
}

export default Post;