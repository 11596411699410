import firebase from "firebase";
import QRCode from "./QRCode";
import { Dialog, Transition } from '@headlessui/react'
import MyCurrentEvents from "./MyCurrentEvents";
import {useProfileData} from "../hooks/useProfileData";
import {SupportIcon} from '@heroicons/react/outline'
import {Fragment, useRef, useState} from "react";

export default function Home() {
    const [open, setOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const user = firebase.auth().currentUser;
    const {speaker} = useProfileData();

    return (
        <div className="container mx-auto" style={{maxWidth: "470px"}}>
            <header>
                <div className="max-w-7xl mx-auto py-8">
                    <h1 className="text-3xl font-bold leading-tight text-terminal-blue">Hej {user.displayName.split(" ")[0]}!</h1>
                </div>
            </header>

            <div className="rounded-lg overflow-hidden divide-gray-200">
                <MyCurrentEvents/>
                <div
                    className="flex flex-col content-center justify-center items-center w-full shadow bg-white rounded">
                    <QRCode/>
                    {speaker?.t && (
                        <span className="text-2xl font-bold pb-8 leading-tight">{speaker?.t}</span>
                    )}

                </div>
            </div>

            <div className="inset-x-0 bottom-0 mt-4">
                <div className="mx-auto max-w-7xl">
                    <div className="rounded-lg bg-terminal-blue p-2 shadow-lg sm:p-3">
                        <div className="flex flex-wrap items-center justify-between">
                            <div className="flex w-0 flex-1 items-center">
              <span className="flex rounded-lg bg-indigo-800 p-2">
                <SupportIcon className="h-6 w-6 text-white" aria-hidden="true"/>
              </span>
                                <p className="ml-3 truncate font-medium text-sm text-white">
                                    <span className="">Emergency: +386 40 280 837</span>
                                </p>
                            </div>
                            <div className="order-3">
                                <a
                                    onClick={() => setOpen(true)}
                                    className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-terminal-blue shadow-sm hover:bg-indigo-50"
                                >
                                    Call
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <SupportIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                            <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                                Is this an emergency?
                                            </Dialog.Title>
                                            <div className="mt-2">
                                                <p className="text-sm text-gray-500">
                                                    This number should be used only in case of an emergency. For FAQ, advice and general recommendations use the official <b>kb-ljubljana-22</b> Slack channel.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                        <a
                                            href="tel:+386040280837"
                                            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                        >
                                            Yes, Call
                                        </a>
                                        <button
                                            type="button"
                                            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                            onClick={() => setOpen(false)}
                                            ref={cancelButtonRef}
                                        >
                                            No, Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
}
