import { Link } from "react-router-dom";
import Event from "./Event";
import { OfficeBuildingIcon, UserGroupIcon } from "@heroicons/react/solid";
import employees from "../content/employees.json";
import {findPicture} from "../util/helpers";

export function EventCardWithLink(params) {
  return (
    <Link to={`/signup/${params.event.id}`}>
      <EventCard {...params} />
    </Link>
  );
}

export default function EventCard({
  event,
  direction,
  children,
  itemFlex = "items-center",
}) {

  return (
    <li key={`event-${event.id}`} className="border-t">
      <div className={`flex ${itemFlex} px-4 py-4 sm:px-6 ${direction || ""}`}>
        <div className="min-w-0 flex-1 flex items-center">
          {event.speakers && event.speakers.length > 0 ? (
              <div className="flex-shrink-0">
                <div className="isolate flex -space-x-4 overflow-hidden items-center">
                  {event.speakers.map(speaker => (
                      <img
                          className="relative z-0 inline-block h-12 w-12 rounded-full ring-2 ring-white object-cover object-top"
                          src={findPicture(speaker.email)}
                          alt=""
                      />
                  ))}
                </div>
              </div>
          ) :(
              <img
                  className="h-12 w-12 rounded-full object-cover object-center"
                  src="/logo-square.svg"
                  alt=""
              />
          )}
          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
            <div>
              <p className="text-sm font-medium text-terminal-blue truncate">
                {event.title}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500">
                {
                  event.speakers ?
                      event.speakers.map((speaker, index) =>
                          {
                            const [firstName, lastName, ...rest] = speaker.name.split(" ");
                            let displayName;

                            if(event.speakers.length === 1) {
                              displayName = firstName + " " + lastName + " " + rest;
                            } else {
                              displayName = firstName + " " + lastName[0] + "."
                            }

                            return (
                                <>
                                  {index > 0 && (
                                      <span className="mx-1">&</span>
                                  )}
                                  <span className="truncate">{displayName}</span>
                                </>
                            )
                          }
                      )
                      : (
                          <span className="truncate">13|37</span>
                      )
                }
              </p>
            </div>
            <div className="hidden md:block">
              <div>
                <p className="mt-2 w-full flex items-center text-sm text-gray-500">
                  <OfficeBuildingIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  {event.venue ? event.venue : "Unknown Venue"}
                </p>
                <p className="mt-2 w-full flex items-center text-sm text-gray-500">
                  <UserGroupIcon
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                  {event.attending?.length || 0}{(event.limit > 0) && ` / ${event.limit}`}
                </p>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </li>
  );
}
