export default function Map() {
  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <iframe
        src="https://www.google.com/maps/d/u/1/embed?mid=10xRO1UskW72X3T0YwHnlGibZv64fHDU&ehbc=2E312F&ll=46.053396395424095%2C14.518907850000002&z=14"
        className="h-full w-full map"
        frameborder="0"
      ></iframe>
    </div>
  );
}
