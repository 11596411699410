import "./App.css";
import {
    FirebaseAuthProvider,
    FirebaseAuthConsumer,
} from "@react-firebase/auth";
import firebase, {firebaseConfig} from "./config/firebase";
import {Routes, Route, Link} from "react-router-dom";
import Login from "./components/Login";
import Feed from "./components/Feed";
import People from "./components/Home";
import Layout from "./components/Layout";
import Calendar from "./components/Calendar";
import Events from "./components/Events";
import Ratings from "./components/Ratings";
import AddEvents from "./components/AddEvents";
import Map from "./components/Map";
import Location from "./components/Location";
import CurrentEventsProvider from "./hooks/useMyCurrentEvents";
import Event from "./components/Event";
import Details from "./components/Details";
import ProfileDataProvider from "./hooks/useProfileData";

function App() {
    return (
        <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
            <FirebaseAuthConsumer>
                {({isSignedIn, user}) => {
                    if (isSignedIn) {
                        return (
                            <CurrentEventsProvider>
                                <ProfileDataProvider>
                                    <Layout>
                                        <Routes>
                                            <Route path="/" element={<People/>}/>
                                            <Route path="feed" element={<Feed/>}/>
                                            <Route path="schedule" element={<Calendar/>}/>
                                            <Route path="signup" element={<Events/>}/>
                                            <Route path="/signup/:id" element={<Event/>}/>
                                            <Route path="ratings" element={<Ratings/>}/>
                                            <Route path="signup/add" element={<AddEvents/>}/>
                                            <Route path="guide" element={<Map/>}/>
                                            <Route path="details" element={<Details/>}/>
                                            <Route path="location" element={<Location/>}/>
                                        </Routes>
                                    </Layout>
                                </ProfileDataProvider>
                            </CurrentEventsProvider>
                        );
                    } else {
                        return <Login/>;
                    }
                }}
            </FirebaseAuthConsumer>
        </FirebaseAuthProvider>
    );
}

export default App;
