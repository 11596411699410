import {Fragment, useEffect} from "react";
import {Disclosure, Menu, Transition} from "@headlessui/react";
import {MenuIcon, XIcon, ChatIcon} from "@heroicons/react/outline";
import firebase from "../config/firebase";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {findPicture} from "../util/helpers";

const navigation = [
    {name: "Sign Up", href: "/signup", current: true},
    {name: "Schedule", href: "/schedule", current: false},
    {name: "Feed", href: "/feed", current: false},
    {name: "Guide", href: "/guide", current: false},
    {name: "Location", href: "/location", current: false},
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function Layout({children}) {
    const user = firebase.auth().currentUser;
    const navigationFn = useNavigate();

    const signOut = async function () {
        await firebase.auth().signOut();
    };

    const userNavigation = [
        {name: "Details", onClick: () => navigationFn("/details")},
        {name: "Sign out", onClick: () => signOut()}
    ];

    useEffect(() => {
        document.body.classList.add("h-full");
        document.documentElement.classList.add("h-full");

        return () => {
            document.body.classList.remove("h-full");
            document.documentElement.classList.remove("h-full");
        };
    }, []);

    let location = useLocation();

    return (
        <div className="min-h-full">
            <div className="h-16"/>
            <Disclosure
                as="nav"
                className="bg-white border-b border-gray-200 fixed w-full top-0 z-50"
            >
                {({open, close}) => (
                    <>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="flex justify-between h-16">
                                <div className="flex">
                                    <div className="flex-shrink-0 flex items-center">
                                        <img
                                            className="block h-8 w-auto"
                                            src="/logo-square.svg"
                                            alt="Workflow"
                                        />
                                        <Link
                                            to="/"
                                            onClick={close}
                                            className="ml-4 font-bold text-terminal-blue"
                                        >
                                            Knowabunga
                                        </Link>
                                    </div>
                                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                                        {navigation.map((item) => (
                                            <div
                                                key={item.name}
                                                className={classNames(
                                                    location.pathname === item.href
                                                        ? "border-terminal-blue text-gray-900"
                                                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                                                    "inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
                                                )}
                                                aria-current={
                                                    location.pathname === item.href ? "page" : undefined
                                                }
                                            >
                                                <Link to={item.href}>{item.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                    {/* Profile dropdown */}
                                    <div
                                        key="ratings"
                                        as="div"
                                        onClick={() => {
                                            navigationFn("/ratings");
                                            close();
                                        }}
                                        className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <ChatIcon className="block h-6 w-6" aria-hidden="true"/>
                                    </div>
                                    <Menu as="div" className="ml-3 relative">
                                        <div>
                                            <Menu.Button
                                                className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-terminal-blue">
                                                <span className="sr-only">Open user menu</span>
                                                <img
                                                    className="h-8 w-8 rounded-full object-cover object-top"
                                                    src={findPicture(user.email)}
                                                    alt=""
                                                />
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items
                                                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                {userNavigation.map((item) => (
                                                    <Menu.Item key={item.name}>
                                                        {({active}) => (
                                                            <div
                                                                onClick={item.onClick}
                                                                className={classNames(
                                                                    active ? "bg-gray-100" : "",
                                                                    "block px-4 py-2 text-sm text-gray-700"
                                                                )}
                                                            >
                                                                {item.name}
                                                            </div>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </div>
                                <div className="-mr-2 flex items-center sm:hidden">
                                    {/* Mobile menu button */}
                                    <div
                                        key="ratings"
                                        as="div"
                                        onClick={() => {
                                            navigationFn("/ratings");
                                            close();
                                        }}
                                        className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        <ChatIcon className="block h-6 w-6" aria-hidden="true"/>
                                    </div>
                                    <Disclosure.Button
                                        className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                        <span className="sr-only">Open main menu</span>
                                        {open ? (
                                            <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                        ) : (
                                            <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        <Disclosure.Panel className="sm:hidden">
                            <div className="pt-2 pb-3 space-y-1">
                                {navigation.map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        as="div"
                                        onClick={() => {
                                            navigationFn(item.href);
                                        }}
                                        className={classNames(
                                            location.pathname === item.href
                                                ? "bg-indigo-50 border-terminal-blue text-terminal-blue"
                                                : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800",
                                            "block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                        )}
                                        aria-current={item.current ? "page" : undefined}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                            <div className="pt-4 pb-3 border-t border-gray-200">
                                <div className="flex items-center px-4">
                                    <div className="flex-shrink-0">
                                        <img
                                            className="h-10 w-10 rounded-full"
                                            src={user.photoURL}
                                            alt=""
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <div className="text-base font-medium text-gray-800">
                                            {user.name}
                                        </div>
                                        <div className="text-sm font-medium text-gray-500">
                                            {user.email}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3 space-y-1">
                                    {userNavigation.map((item) => (
                                        <Disclosure.Button
                                            key={item.name}
                                            as="a"
                                            href={item.href}
                                            onClick={item.onClick}
                                            className="block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                                        >
                                            {item.name}
                                        </Disclosure.Button>
                                    ))}
                                </div>
                            </div>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>

            <div>
                <main>
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0 pb-8">{children}</div>
                    </div>
                </main>
            </div>
        </div>
    );
}
