import * as employees from '../content/employees.json';

export function prettyDate(time) {
    let date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        day_diff = Math.floor(diff / 86400);

    if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31) return;

    return day_diff === 0 && (
        // eslint-disable-next-line
        (diff < 60 && "just now" || diff < 120 && "1 minute ago" || diff < 3600 && Math.floor(diff / 60) + " minutes ago" || diff < 7200 && "1 hour ago" || diff < 86400 && Math.floor(diff / 3600) + " hours ago") || day_diff === 1 && "Yesterday" || day_diff < 7 && day_diff + " days ago" || day_diff < 31 && Math.ceil(day_diff / 7) + " weeks ago")
}


export function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export function findPicture(email) {
    const splitEmail = email.split("@");
    const employeeObj = employees[`${splitEmail[0]}@1337.tech`];
    if (employeeObj && employeeObj.imagePortraitUrl) {
        return `${employeeObj.imagePortraitUrl}-small`;
    } else {
        return  "/missing-profile.png"
    }
}


export function isSameTime(eventOne, eventTwo) {
    return new Date(eventOne).getTime() === new Date(eventTwo).getTime()
}
