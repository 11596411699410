import { useRef, useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import firebase, { db } from "../config/firebase";
import employees from "../content/employees.json";
import { PlusSmIcon } from "@heroicons/react/outline";

const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
// store markers in db make scheduled function to remove them after an hour
// store user data to database and fetch from there (no need to store state?)

function Map({ markers, center }) {
  const ref = useRef(null);
  const map = useRef(null);
  let currentMarkers = useRef([]);

  // center ljubljana
  const zoom = 13;

  useEffect(() => {
    map.current = new window.google.maps.Map(ref.current, {
      center,
      zoom,
    });
  }, []);

  useEffect(() => {
    map.current.setCenter(center);
  }, [center]);

  useEffect(() => {
    currentMarkers.current.forEach((marker) => {
      marker.setMap(null);
    });
    currentMarkers.current = [];
    markers.forEach((marker) => {
      const mapMarker = new window.google.maps.Marker({
        position: {
          lat: marker.lat,
          lng: marker.lng,
        },

        title: `positionMarker-${marker.email}`,
        label: marker.label,
      });
      mapMarker.setMap(map.current);
      currentMarkers.current.push(mapMarker);
    });
  }, [markers]);

  return <div className="w-full h-full" id="map" ref={ref}></div>;
}

export default function Location() {
  const [markers, setMarkers] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [center, setCenter] = useState({ lat: 46.056946, lng: 14.505751 });
  const [time, setTime] = useState(new Date().getTime());

  function shareLocation(description) {
    // add location to db
    console.log("share my location");
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const { latitude, longitude } = position.coords;

        const email = firebase.auth().currentUser.email;
        await db.collection("locations").doc(email).set({
          lat: latitude,
          lng: longitude,
          description,
          timestamp: firebase.firestore.Timestamp.now(),
        });
      },
      (err) => {
        console.log(err);
      }
    );
  }

  useEffect(() => {
    const HOUR_IN_MS = 3600000;
    const unsubscribe = db
      .collection("locations")
      .where("timestamp", ">", new Date(time - HOUR_IN_MS))
      .onSnapshot((locations) => {
        setMarkers(
          locations.docs.map((doc, index) => {
            const marker = doc.data();
            const label = labels[index++ % labels.length];

            return {
              ...marker,
              label,
              email: doc.id,
            };
          })
        );
      });
    return unsubscribe;
  }, [time]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().getTime());
    }, 60000);
  }, []);

  function render(status) {
    if (Status.LOADING === status || Status.FAILURE === status) {
      return <div></div>;
    }
    return null;
  }

  return (
    <div style={{ height: "calc(100vh - 64px)" }}>
      <div className="h-3/5">
        <Wrapper
          apiKey="AIzaSyBTvonm3XoYHlEZgan-v3WwUrGnF1MI3UE"
          render={render}
        >
          <Map markers={markers} center={center}></Map>
        </Wrapper>
      </div>

      <div className="bg-white w-full border h-2/5">
        <div className="py-4 overflow-auto h-full">
          <div>
            {markers.map((marker) => {
              const employee = employees[marker.email];
              return (
                <div className="flex flex-col items-start min-h-10 px-10 mb-2">
                  <div className="w-full flex">
                    <div
                      className="h-6 w-6 bg-red-400 rounded-full text-center mr-2"
                      onClick={() => {
                        setCenter({ lat: marker.lat, lng: marker.lng });
                      }}
                    >
                      {marker.label}
                    </div>
                    {employee ? (
                      <span className="text-terminal-blue">
                        {employee.name}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>{marker.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50 h-full w-full"
      >
        <div className="fixed inset-0 w-full h-full opacity-50 bg-gray-400"></div>
        <div className="fixed top-52 flex items-center justify-center h-fit p-4 ">
          <div className="bg-white rounded w-full p-4">
            <Dialog.Panel>
              <Dialog.Title className="text-terminal-blue">
                Add description
              </Dialog.Title>
              <Dialog.Description>
                <p className="text-gray-400">
                  The location will be visible on the map for 1 hour. You can
                  also add a description for others to see
                </p>
                <input
                  id="location-description"
                  placeholder="description"
                  type="text"
                  className="w-full my-2"
                />
              </Dialog.Description>

              <button
                className="float-right text-leet-green"
                onClick={() => {
                  const description = document.getElementById(
                    "location-description"
                  ).value;
                  shareLocation(description);
                  setIsOpen(false);
                }}
              >
                Share
              </button>
              <button
                className="float-right mr-2"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
      <div>
        <button
          id="button"
          style={{
            boxShadow: `0 10px 15px -3px rgb(0 0 0 / 0.25), 0 4px 6px -4px rgb(0 0 0 / 0.25)`,
          }}
          className="inline-flex fixed shadow-2xl bottom-8 right-8 items-center p-3 border border-transparent rounded-full shadow-sm text-white bg-terminal-blue hover:bg-terminal-blue-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <PlusSmIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </div>
  );
}
